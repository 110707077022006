import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants/static';
import dotsGray from '../../assets/images/dots-gray.svg';
import dotsTurquoise from '../../assets/images/dots-turquoise.svg';

const CardOuter = styled.div`
  ${({ shadow }: { shadow: boolean }) =>
    shadow ? 'box-shadow: 0px 5px 60px rgba(229, 229, 229, 0.6);' : ''}
  border-radius: 22px;
  flex-grow: 1;
  min-height: 425px;
  padding: 20px 13px;
`;

const CardTitle = styled.h4`
  text-align: center;
  font-size: 18px;
  margin-top: 32px;
`;

const CardDescription = styled.div`
  color: ${COLORS.GRAY3};
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
`;

const CardImage = styled.div`
  width: 150px;
  margin: 0 auto;
  padding-top: 44px;
  background: url(${({ highlighted }: { highlighted?: boolean }) =>
      highlighted ? dotsTurquoise : dotsGray})
    no-repeat bottom right;
`;

const CardIconHolder = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 55px;
  margin: 0 auto;
  display: flux;
  justify-content: center;
  align-items: center;
  background-color: ${({ highlighted }: { highlighted?: boolean }) =>
    highlighted ? COLORS.DARK_BLUE : COLORS.TURQUOISE};
`;

const Card = ({
  highlighted,
  title,
  description,
  icon,
}: {
  highlighted?: boolean;
  title: string;
  description: string;
  icon: any;
}) => {
  console.log(highlighted);
  return (
    <CardOuter shadow={!!highlighted}>
      <CardImage highlighted={highlighted}>
        <CardIconHolder highlighted={highlighted}>
          {icon ? icon : ''}
        </CardIconHolder>
      </CardImage>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardOuter>
  );
};

export default Card;
