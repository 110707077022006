import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants/static';

const Text = styled.span`
  color: ${COLORS.TURQUOISE};
`;
const TurquoiseText = ({ children }: { children: any }) => (
  <Text>{children}</Text>
);

export default TurquoiseText;
