import React from 'react';
import styled from 'styled-components';

import checkIcon from '../../assets/icons/check.svg';
import communicationIcon from '../../assets/icons/communication.svg';
import deliveryTrackingIcon from '../../assets/icons/deliveryTracking.svg';
import folderIcon from '../../assets/icons/folder.svg';
import infoIcon from '../../assets/icons/info.svg';
import listIcon from '../../assets/icons/list.svg';
import notificationIcon from '../../assets/icons/notification.svg';
import refreshIcon from '../../assets/icons/refresh.svg';
import reportIcon from '../../assets/icons/report.svg';
import visionIcon from '../../assets/icons/vision.svg';
import websiteDesignIcon from '../../assets/icons/website-design.svg';

const Icon = styled.i`
  width: 31px;
  height: 31px;
  background: url(${(props: { icon: any }) => props.icon}) no-repeat center
    center;
  display: block;
`;

const iconMapping = {
  check: checkIcon,
  communication: communicationIcon,
  deliveryTracking: deliveryTrackingIcon,
  folder: folderIcon,
  info: infoIcon,
  list: listIcon,
  notification: notificationIcon,
  refresh: refreshIcon,
  report: reportIcon,
  vision: visionIcon,
  websiteDesign: websiteDesignIcon,
};

const SvgIcon = ({
  icon,
}: {
  icon:
    | 'communication'
    | 'deliveryTracking'
    | 'folder'
    | 'info'
    | 'list'
    | 'notification'
    | 'refresh'
    | 'report'
    | 'vision'
    | 'websiteDesign'
    | 'check';
}) => {
  return <Icon icon={iconMapping[icon]} />;
};

export default SvgIcon;
