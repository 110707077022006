import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import appScreenTwo from '../../assets/images/app-screen-2.png';
import polygonTop from '../../assets/images/polygon-1.svg';
import pathBottom from '../../assets/images/path-174.svg';
import { COLORS } from '../../constants/static';
import ListWithIcons from '../common/ListWithIcons';
import SvgIcon from '../common/SvgIcon';
import TextBox from '../common/TextBox';

const AppScreenImageTwo = styled.img`
  max-width: 360px;
  margin: 0 auto;
  width: 100%;
`;

const ImageWrapOuter = styled.div`
  background: url(${polygonTop}) no-repeat left top;
  margin: 0 auto;
`;

const ImageWrapInner = styled.div`
  background: url(${pathBottom}) no-repeat right bottom;
  padding: 2em 5em;
`;

const SectionThree = () => {
  const firstListItems = [
    {
      text: 'Empower your workers to be your eyes in the field',
      icon: <SvgIcon icon="vision"></SvgIcon>,
    },
    {
      text: 'Build custom checklists and conduct onsite inspections',
      icon: <SvgIcon icon="list"></SvgIcon>,
    },
    {
      text: '100% digital ticketing system',
      icon: <SvgIcon icon="websiteDesign"></SvgIcon>,
    },
  ];

  return (
    <Box pt={20}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box pr={5} display="flex">
              <ImageWrapOuter>
                <ImageWrapInner>
                  <AppScreenImageTwo src={appScreenTwo} alt="App Screen 2" />
                </ImageWrapInner>
              </ImageWrapOuter>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box pt={10}>
              <Typography variant="h3">Create and Assign Tasks</Typography>
            </Box>
            <Box pt={4}>
              <TextBox>
                We have made creating tasks as easy and powerful as possible. We
                care about convenience and functionality.
                <Box pt={3}>
                  <ListWithIcons listItems={firstListItems} />
                </Box>
              </TextBox>
            </Box>
            <Box></Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SectionThree;
