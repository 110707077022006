import { Box, Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants/static';
import LearnMoreLink from '../common/LearnMoreLink';
import Span from '../common/Span';
import TurquoiseText from '../common/TurquoiseText';
import SatisfiedClients from './SatisfiedClients';
import SectionFive from './SectionFive';
import SectionFour from './SectionFour';
import SectionOne from './SectionOne';
import SectionThree from './SectionThree';
import SectionTwo from './SectionTwo';

const GradientBox = styled.div`
  background: linear-gradient(
    180deg,
    rgba(243, 243, 243, 0.66) 0px,
    rgba(243, 243, 243, 0) 656px
  );
`;

const ProductivityBox = styled.div`
  text-align: center;
  padding: 5em;
  background-color: ${COLORS.LIGHTER_GRAY};
`;

const Home = () => {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <GradientBox>
        <SectionThree />
        <SectionFour />
        <SectionFive />
        {/*<SatisfiedClients />*/}
        <Box pb={20}>
          <Container>
            <ProductivityBox>
              <Typography variant="h2">
                <Span weight={500}>Take Your Operation To The </Span>{' '}
                <TurquoiseText>Next Level</TurquoiseText>
              </Typography>
              <Box pt={2}>
                Empower your field teams, streamline your operations, and deliver customer happiness. And help your bottom line while doing it.             
              </Box>
              <Box pt={5}>
                <LearnMoreLink extraPadding />
              </Box>
            </ProductivityBox>
          </Container>
        </Box>
      </GradientBox>
    </div>
  );
};

export default Home;
