import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState }  from 'react';
import styled from 'styled-components';
import appScreenOne from '../../assets/images/app-screens/screen-1.png';
import appScreenTwo from '../../assets/images/app-screens/screen-2.png';
import appScreenThree from '../../assets/images/app-screens/screen-3.png';
import appScreenFour from '../../assets/images/app-screens/screen-4.png';
import appScreenFive from '../../assets/images/app-screens/screen-5.png';
import appScreenSix from '../../assets/images/app-screens/screen-6.png';
import appScreenSeven from '../../assets/images/app-screens/screen-7.png';
import appScreenEight from '../../assets/images/app-screens/screen-8.png';
import { COLORS } from '../../constants/static';
import LearnMoreLink from '../common/LearnMoreLink';
import Span from '../common/Span';
import TurquoiseText from '../common/TurquoiseText';
import SectionOneBg from './SectionOneBg';
import appScreenBg from '../../assets/images/app-screen-1.png';

const FirstSectionText = styled.span`
  color: ${COLORS.GRAY1};
  font-size: 32px;
  line-height: 1.42;
`;

const BackgroundBox = styled.div`
  width: 120%;
  margin-right: -20%;
  position: relative;

  @media (max-width: 1500px) {
    width: 100%;
    margin-right: 0;
    padding-top: 10em;
  }
`;

const BackgroundBoxOuter = styled.div`
  width: 100%;
  padding-bottom: ${2016 / 2189 * 100}%;
`;
const BackgroundBoxInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const AppScreenImageOne = styled.div`
  width: 50%;
  padding-bottom: ${1252/720*50}%;
  position: relative;
  margin-top: -5%;
`;

const ImageBoxOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliderOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(${appScreenBg});
  background-size: cover;
  z-index: 3;
`;

const ImageBox = styled.div`
  width: 64.2%;
  margin-top: -7%;
  margin-left: -6.54%;
  height: 80%;
  position: relative; 
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: opacity 1s;
  ${({ next, current }: { next?: boolean, current?: boolean }) => {
  if (next) {
    return `
        z-index: 1;
        opacity: 1;
      `;
  }

  if (current) {
    return `
        z-index: 2;
        opacity: 1;
      `;
  }
  
  return `
    z-index: 3;
    opacity: 0;
  `;
  }};
`;

const fadeImages = [
  appScreenOne,
  appScreenTwo,
  appScreenThree,
  appScreenFour,
  appScreenFive,
  appScreenSix,
  appScreenSeven,
  appScreenEight,
];

const SectionOne = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currIndex: number) => currIndex + 1 === fadeImages.length ? 0 : currIndex + 1);
      setNextIndex((nxtIndex: number) => nxtIndex + 1 === fadeImages.length ? 0 : nxtIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  
  return (<Box pt={4}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box pt={10}>
              <Typography variant="h1">
                <Span weight={400}>Eyes and ears into</Span> <br />
                <TurquoiseText>Your Operation.</TurquoiseText>
              </Typography>
            </Box>
            <Box pt={4} pb={4}>
              <FirstSectionText>
		The easiest platform for managing your field team.
            </FirstSectionText>
            </Box>
            <LearnMoreLink extraPadding />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box display="flex">
              <BackgroundBox>
                <BackgroundBoxOuter>
                  <SectionOneBg />
                  <BackgroundBoxInner>
                  <AppScreenImageOne>
                      <ImageBoxOuter>
                        <ImageBox>
                          {fadeImages.map((image, index) => (
                            <Image
                              key={index}
                              current={currentIndex === index}
                              next={nextIndex === index}
                              src={image} />))}
                        </ImageBox>
                        <SliderOverlay/>
                      </ImageBoxOuter>
                    </AppScreenImageOne>
                  </BackgroundBoxInner>
                </BackgroundBoxOuter>
              </BackgroundBox>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
};

export default SectionOne;
