import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import styled from 'styled-components';
import ListWithIcons from '../common/ListWithIcons';
import Span from '../common/Span';
import SvgIcon from '../common/SvgIcon';
import TurquoiseText from '../common/TurquoiseText';

const ContactUsPage = styled.div`
  min-height: calc(100vh - 414px);
  padding: 10em 0;
  box-sizing: border-box;
`;

const Form = styled.form`
  background: #ffffff;
  box-shadow: 0px 5px 60px rgba(229, 229, 229, 0.6);
  border-radius: 22px;
  padding: 4em;
  flex-grow: 1;
`;
const listItems = [
  {
    text: 'Empower your team with the information they need in the field',
    icon: <SvgIcon icon="check"></SvgIcon>,
  },
  {
    text: 'No more phone calls, texts, Slacks, and WhatsApp messages from the field - we streamline it all',
    icon: <SvgIcon icon="check"></SvgIcon>,
  },
  {
    text: 'Impress your customers with a consistent experience, day',
    icon: <SvgIcon icon="check"></SvgIcon>,
  },
  {
    text: 'Watch your business grow efficiently and profitably',
    icon: <SvgIcon icon="check"></SvgIcon>,
  },
];


const ContactUs = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [numberOfLocations, setNumberOfLocations] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    // @ts-ignore
    analytics.track('contactFormSubmit', {
      firstName,
      lastName,
      company,
      phoneNumber,
      numberOfLocations
    }, null, (e: any) => {
        console.log(e);
        setIsLoading(false);
        setIsSubmitted(true);
    });
  }

  return (
    <ContactUsPage>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box pt={8}>
              <Typography variant="h2">
                <Span weight={400}>You’re one step away from</Span> <br />
                <TurquoiseText>Running Your Operation On Auto-Pilot</TurquoiseText>
              </Typography>
            </Box>
            <Box pt={4} pr={12}>
              <ListWithIcons listItems={listItems} />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" pt={2}>
              <Form onSubmit={submitForm}>
                <Typography variant="h2">
                  <Span weight={400}>Get in touch</Span>
                </Typography>
                <Box pb={3} pt={4}>
                  <TextField fullWidth label="First Name" variant="outlined" onChange={(e: any) => setFirstName(e.target.value)} required/>
                </Box>
                <Box pb={3}>
                  <TextField fullWidth label="Last Name" variant="outlined" onChange={(e: any) => setLastName(e.target.value)} required/>
                </Box>
                <Box pb={3}>
                  <TextField fullWidth label="Company" variant="outlined" onChange={(e: any) => setCompany(e.target.value)} required/>
                </Box>
                <Box pb={3}>
                  <TextField
                    fullWidth
                    required
                    label="Phone Number"
                    variant="outlined"
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                  />
                </Box>
                <Box pb={5}>
                  <TextField
                    fullWidth
                    label="Number of Company Locations"
                    variant="outlined"
                    onChange={(e: any) => setNumberOfLocations(e.target.value)}
                  />
                </Box>
                <Box textAlign="right">
                  {!isSubmitted && (<Button color="primary" variant="contained" disableElevation type="submit" disabled={isLoading}>
                    Contact Us
                  </Button>)}
                  {isSubmitted && <Alert severity="success">Form submitted successfully!</Alert>}
                </Box>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ContactUsPage>
  );
};

export default ContactUs;
