import styled from 'styled-components';

const Span = styled.span`
  ${({ weight, color }: { weight?: any; color?: string }) => `
    font-weight: ${weight || 500};
    ${color ? `color: ${color}` : ''}
  `};
`;

export default Span;
