import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const StyledButton = styled(Button)`
  ${({ extraPadding }: { extraPadding?: boolean }) =>
    extraPadding && 'padding-left: 3em; padding-right: 3em;'}
`;

const LearnMoreLink = ({ extraPadding }: { extraPadding?: boolean }) => {
  const history = useHistory();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();

    history.push('/contact-us');
  };
  return (
    <StyledButton
      extraPadding={extraPadding}
      color="primary"
      variant="contained"
      disableElevation
      href="/contact-us"
      onClick={onClick}
    >
      Learn more
    </StyledButton>
  );
};

export default LearnMoreLink;
