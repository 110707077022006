import React from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';

import appScreenOne from '../../assets/images/app-screen-1.png';
import TurquoiseText from '../common/TurquoiseText';
import Span from '../common/Span';
import { COLORS } from '../../constants/static';
import Card from './Card';
import SvgIconBig from '../common/SvgIconBig';

const FirstSectionText = styled.span`
  font-color: ${COLORS.GRAY1};
  font-size: 32px;
  line-height: 1.42;
`;

const AppScreenImageOne = styled.img`
  max-width: 360px;
  margin: 0 auto;
  width: 100%;
`;

const SectionTwo = () => (
  <Box>
    <Box>
      <Container>
        <Box pt={2}>
          <Typography variant="h3" align="center">
            <Span weight={400}>Execute Flawlessly,</Span>{' '}
            <TurquoiseText>Every Time</TurquoiseText>
          </Typography>
        </Box>
      </Container>
    </Box>
    <Box pt={10} pb={10} overflow="hidden">
      <Container>
        <Box>
          <Grid container spacing={10}>
            <Grid item md={4} xs={12}>
              <Card
                title="Customizable Workflows"
                description="Fully customizable tasks based on your business needs"
                icon={<SvgIconBig icon="list"></SvgIconBig>}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                highlighted
                title="Real-Time Field Reporting"
                description="Responsive dashboards with live photo progress from the field"
                icon={<SvgIconBig icon="monitor"></SvgIconBig>}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                title="COVID Compliance"
                description="Best practices and guideline templates from the WHO and CDC"
                icon={<SvgIconBig icon="disinfectant"></SvgIconBig>}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  </Box>
);

export default SectionTwo;
