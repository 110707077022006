import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { COLORS } from '../constants/static';
import logoImage from '../assets/images/tangelo-ai-logo-light.png';


const Footer = styled.footer`
  background: ${COLORS.DARK_BLUE};
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  padding-top: 170px;
  color: ${COLORS.WHITE};
`

const SiteLogo = styled.img`
  display: block;
  height: 38px;
  width: 155px;
`;

const FooterMenuContainer = styled.div`
`;

const MenuTitle = styled.h5`
  font-size: 18px;
  font-weight: 600;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
`;

const FooterMenu = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

const FooterMenuItem = styled.li`
  a {
    color: ${COLORS.WHITE};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AppFooter = () => {
  const currentYear = (new Date()).getFullYear();

  return (
    <Footer>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <RouterLink to="/"><SiteLogo src={logoImage} alt="Tangelo" /></RouterLink>
          </Grid>
          <Grid item md={2} xs={6}>
            <FooterMenuContainer>
              <MenuTitle>Site Navigation</MenuTitle>
              <FooterMenu>
                <FooterMenuItem><RouterLink to="/contact-us">Contact Us</RouterLink></FooterMenuItem>
              </FooterMenu>
            </FooterMenuContainer>
          </Grid>
        </Grid>
        <Box pt={5} pb={5}>
          Copyright © TangeloAi {currentYear}
        </Box>
      </Container>

    </Footer>
  )
};

export default AppFooter;
