import {
  Avatar,
  createStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      fontSize: 24,
    },
    avatar: {
      width: 60,
      height: 60,
      background: '#FFFFFF',
      border: '2px solid #50CEA2',
      boxShadow: '0px 5px 60px rgba(229, 229, 229, 0.7)',
      borderRadius: '30px',
      boxSizing: 'border-box',
      marginRight: 24,
    },
  })
);

const ListWithIcons = ({ listItems }: { listItems: any[] }) => {
  const classes = useStyles();

  return (
    <List>
      {listItems.map((item, index) => (
        <ListItem disableGutters key={index}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {item.icon ? item.icon : ''}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.listItem }}
            primary={item.text}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ListWithIcons;
