import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import appScreenFour from '../../assets/images/app-screen-4.png';
import ListWithIcons from '../common/ListWithIcons';
import pathTop from '../../assets/images/path-176.svg';
import polygonBottom from '../../assets/images/polygon-2.svg';
import SvgIcon from '../common/SvgIcon';
import TextBox from '../common/TextBox';

const AppScreenImageFour = styled.img`
  max-width: 556px;
  margin: 0 auto;
  width: 100%;
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.2));
`;

const ImageWrapOuter = styled.div`
  background: url(${pathTop}) no-repeat left top;
  margin: 0 auto;
`;

const ImageWrapInner = styled.div`
  background: url(${polygonBottom}) no-repeat right bottom;
  padding: 8em 4em 10em 4em;
`;

const SectionFive = () => {
  const thirdListItems = [
    {
      text: 'Custom data + ERP integrations make your team shine',
      icon: <SvgIcon icon="folder"></SvgIcon>,
    },
    {
      text:
        'Quickly build trust with your customers by showing them key account information',
      icon: <SvgIcon icon="refresh"></SvgIcon>,
    },
    {
      text: 'Automated notifications when your work is completed',
      icon: <SvgIcon icon="notification"></SvgIcon>,
    },
  ];

  return (
    <Box pt={20} pb={20}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box display="flex">
              <ImageWrapOuter>
                <ImageWrapInner>
                  <AppScreenImageFour src={appScreenFour} alt="App Screen 4" />
                </ImageWrapInner>
              </ImageWrapOuter>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography variant="h3">Impress your Customers</Typography>
            </Box>
            <Box pt={4}>
              <TextBox>
                Delighting customers is not just seeing their needs at the
                moment. It is more about building relationships that stand the
                test of time.
                <Box pt={3}>
                  <ListWithIcons listItems={thirdListItems} />
                </Box>
              </TextBox>
            </Box>
            <Box></Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SectionFive;
