import React from 'react';
import styled from 'styled-components';

import disinfectantIcon from '../../assets/icons/big/disinfectantWhite.svg';
import listIcon from '../../assets/icons/big/listWhite.svg';
import monitorIcon from '../../assets/icons/big/monitorWhite.svg';

const Icon = styled.i`
  width: 50px;
  height: 50px;
  background: url(${(props: { icon: any }) => props.icon}) no-repeat center
    center;
  display: block;
`;

const iconMapping = {
  disinfectant: disinfectantIcon,
  list: listIcon,
  monitor: monitorIcon,
};

const SvgIconBig = ({
  icon,
}: {
  icon: 'disinfectant' | 'list' | 'monitor';
}) => {
  return <Icon icon={iconMapping[icon]} />;
};

export default SvgIconBig;
