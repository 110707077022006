import React from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppHeader from './components/AppHeader';
import Home from './components/home/Home';
import { COLORS } from './constants/static';
import { Box, CssBaseline } from '@material-ui/core';
import AppFooter from './components/AppFooter';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ContactUs from './components/contact-us/ContactUs';

const titleBaseTypography = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.TURQUOISE,
      contrastText: '#FFF',
    },
    secondary: {
      main: COLORS.WHITE,
      dark: COLORS.LIGHT_GRAY,
      contrastText: COLORS.GRAY2,
    },
    text: {
      primary: COLORS.GRAY2,
      secondary: COLORS.GRAY3,
    },
    background: {
      default: COLORS.WHITE,
    },
  },
  typography: {
    fontFamily: ['"Didact Gothic"', 'Arial', 'sans-serif'].join(','),
    h1: { ...titleBaseTypography, fontSize: 60 },
    h2: { ...titleBaseTypography, fontSize: 36 },
    h3: { ...titleBaseTypography, fontSize: 32 },
    h4: { ...titleBaseTypography, fontSize: 20 },
    h5: { ...titleBaseTypography, fontSize: 18 },
    h6: { ...titleBaseTypography },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1rem',
        padding: '14px 28px',
        textTransform: 'none',
        lineHeight: 1.5,
        fontWeight: 400,
        borderRadius: '8px',
        '@media (max-width: 600px)': {
          padding: '10px 14px',
        }
      },
    },
  },
});

const App = () => {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Box color="text.primary">
          <AppHeader></AppHeader>

          <Switch>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <AppFooter></AppFooter>
        </Box>
      </MuiThemeProvider>
    </Router>
  );
};

export default App;
