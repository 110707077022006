import { makeStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const animationTime = 20;

const useStyles = makeStyles(theme => ({
  "@keyframes effectItem1": {
    "0%": {
      opacity: 1,
    },
    "20%": {
      opacity: 0,
    },
    "30%": {
      opacity: 0,
    },
    "40%": {
      opacity: 1,
    }
  },
  svgAnimatedItem1: {
    animation: `$effectItem1 ${animationTime}s infinite`
  },
  "@keyframes effectItem2": {
    "20%": {
      opacity: 1,
    },
    "30%": {
      opacity: 0,
    },
    "60%": {
      opacity: 0,
    },
    "70%": {
      opacity: 1,
    }
  },
  svgAnimatedItem2: {
    animation: `$effectItem2 ${animationTime}s infinite`
  },
  "@keyframes effectItem3": {
    "30%": {
      opacity: 1,
    },
    "40%": {
      opacity: 0,
    },
    "50%": {
      opacity: 0,
    },
    "60%": {
      opacity: 1,
    }
  },
  svgAnimatedItem3: {
    animation: `$effectItem3 ${animationTime}s infinite`
  },
  "@keyframes effectItem4": {
    "50%": {
      opacity: 1,
    },
    "60%": {
      opacity: 0,
    },
    "70%": {
      opacity: 0,
    },
    "80%": {
      opacity: 1,
    }
  },
  svgAnimatedItem4: {
    animation: `$effectItem4 ${animationTime}s infinite`
  },
}));


const SectionOneBg = () => {
  const styles = useStyles();

  return (
    <Svg viewBox="0 0 685 738" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <circle cx="253" cy="124" r="105" fill="#50CEA2" />
      <path d="M537.086 321.407C608.21 398.837 598.699 523.305 515.843 599.414C432.986 675.524 308.16 674.454 237.036 597.025C165.911 519.595 274.146 521.413 357.003 445.303C439.86 369.193 465.962 243.978 537.086 321.407Z" fill="#FFCE55"/>
      <circle cx="679" cy="135" r="6" fill="#50CEA2" fillOpacity="0.3" />
      <circle cx="168" cy="643" r="6" fill="#50CEA2" fillOpacity="0.3" />
      <circle cx="679" cy="418" r="6" fill="#50CEA2" fillOpacity="0.3" />
      <circle className={styles.svgAnimatedItem2} cx="426" cy="15" r="15" fill="#FFCE55" fillOpacity="0.3" />
      <circle className={styles.svgAnimatedItem1} cx="29" cy="512" r="29" fill="#FFCE55" fillOpacity="0.3" />
      <path className={styles.svgAnimatedItem3} d="M140.592 407.904C140.012 407.851 139.011 407.765 138.675 407.737C138.529 407.726 138.377 407.705 138.335 407.698C138.298 407.689 138.097 407.671 137.897 407.659C137.698 407.646 136.636 407.555 135.546 407.457C133.667 407.287 132.241 407.161 131.709 407.113C131.583 407.106 131.051 407.057 130.532 407.01C130.013 406.962 129.455 406.913 129.287 406.899C129.119 406.885 128.427 406.825 127.74 406.764C126.024 406.609 122.918 406.325 117.692 405.865C115.257 405.65 113.278 405.446 113.287 405.41C113.307 405.355 116.288 401.143 117.908 398.877C118.266 398.379 120.995 394.545 123.978 390.352L129.398 382.727L132.332 389.045C133.942 392.523 136.557 398.192 138.147 401.653L141.029 407.943L140.592 407.904ZM134.921 395.47C129.85 384.475 129.393 383.499 129.328 383.568C129.287 383.606 127.747 385.764 125.903 388.356C124.061 390.953 122.455 393.206 122.338 393.372C122.22 393.537 121.628 394.364 121.026 395.215C120.425 396.066 119.854 396.871 119.756 397.008C119.655 397.139 119.182 397.807 118.696 398.488C118.212 399.174 116.972 400.919 115.937 402.369C114.904 403.823 114.044 405.035 114.032 405.066C114.015 405.113 114.964 405.211 118.185 405.494C118.313 405.506 119.86 405.641 121.616 405.797C123.372 405.953 124.947 406.094 125.115 406.108C125.283 406.122 126.402 406.226 127.605 406.33C133.366 406.845 134.32 406.927 134.603 406.953C135.555 407.029 139.547 407.387 139.921 407.43C140.165 407.455 140.377 407.468 140.407 407.461C140.428 407.451 137.958 402.054 134.921 395.47Z" fill="#242953" />
      <path d="M669.308 705.525C668.881 705.486 668.143 705.422 667.895 705.402C667.787 705.394 667.675 705.378 667.644 705.373C667.617 705.366 667.469 705.353 667.322 705.344C667.175 705.335 666.392 705.268 665.589 705.196C664.203 705.07 663.152 704.977 662.76 704.942C662.667 704.937 662.275 704.901 661.893 704.866C661.51 704.83 661.099 704.794 660.975 704.784C660.851 704.774 660.341 704.73 659.835 704.684C658.57 704.571 656.28 704.361 652.428 704.022C650.633 703.863 649.175 703.713 649.182 703.686C649.196 703.646 651.394 700.542 652.587 698.871C652.851 698.504 654.863 695.678 657.062 692.587L661.057 686.967L663.219 691.624C664.407 694.187 666.334 698.366 667.506 700.918L669.63 705.554L669.308 705.525ZM665.128 696.36C661.39 688.256 661.053 687.536 661.005 687.587C660.975 687.615 659.84 689.206 658.481 691.116C657.123 693.03 655.939 694.691 655.853 694.813C655.766 694.935 655.329 695.545 654.886 696.172C654.443 696.799 654.022 697.392 653.95 697.493C653.875 697.59 653.527 698.082 653.169 698.584C652.812 699.09 651.898 700.377 651.135 701.445C650.373 702.517 649.739 703.41 649.731 703.433C649.718 703.468 650.417 703.54 652.792 703.748C652.886 703.758 654.026 703.857 655.321 703.972C656.615 704.087 657.776 704.191 657.9 704.201C658.024 704.212 658.849 704.288 659.735 704.365C663.982 704.744 664.685 704.805 664.894 704.824C665.595 704.88 668.538 705.144 668.814 705.176C668.993 705.194 669.15 705.203 669.171 705.198C669.187 705.191 667.366 701.213 665.128 696.36Z" fill="#242953" />
      <path className={styles.svgAnimatedItem2} d="M77.5829 58.0159C75.734 69.8236 74.2381 79.5232 74.3073 79.5747C74.3994 79.6434 113.059 61.2015 113.714 60.7554C113.846 60.6743 107.578 55.8605 97.5765 48.4093C92.6175 44.7147 88.1385 41.4104 85.1055 39.2041C82.4673 37.2849 80.7913 37.9981 80.2679 41.2179C79.6206 45.1996 78.6539 51.2702 77.5829 58.0159Z" fill="#50CEA2" />
      <path className={styles.svgAnimatedItem4} d="M618.088 311.488C616.859 319.337 615.865 325.784 615.911 325.819C615.972 325.864 641.671 313.605 642.106 313.309C642.194 313.255 638.027 310.055 631.379 305.102C628.747 303.141 626.319 301.346 624.421 299.955C621.789 298.027 620.129 298.728 619.61 301.948C619.2 304.495 618.667 307.843 618.088 311.488Z" fill="#242953" />
    </Svg>
  )
};

export default SectionOneBg;
