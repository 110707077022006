import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import appScreenThree from '../../assets/images/app-screen-3.png';
import pathTop from '../../assets/images/path-175.svg';
import groupBottom from '../../assets/images/group-914.svg';
import ListWithIcons from '../common/ListWithIcons';
import SvgIcon from '../common/SvgIcon';
import TextBox from '../common/TextBox';

const AppScreenImageThree = styled.img`
  max-width: 648px;
  margin: 0 auto;
  width: 100%;
`;

const ImageWrapOuter = styled.div`
  background: url(${pathTop}) no-repeat left top;
  margin: 0 auto;
`;

const ImageWrapInner = styled.div`
  background: url(${groupBottom}) no-repeat right bottom;
  padding: 5em 4em 3em 4em;
`;

const SectionFour = () => {
  const secondListItems = [
    {
      text: 'Real-time reporting from your client locations',
      icon: <SvgIcon icon="report"></SvgIcon>,
    },
    {
      text: 'Smart delivery tracking and signatures',
      icon: <SvgIcon icon="deliveryTracking"></SvgIcon>,
    },
    {
      text: 'Seamless communication across your field team',
      icon: <SvgIcon icon="communication"></SvgIcon>,
    },
    {
      text: 'Key client information is at your team’s fingertips',
      icon: <SvgIcon icon="info"></SvgIcon>,
    },
  ];

  return (
    <Box pt={40}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={5} xs={12}>
            <Box pt={0}>
              <Typography variant="h3">
                Manage your Operation Remotely
              </Typography>
            </Box>
            <Box pt={4}>
              <TextBox>
                Get visibility into your productivity, compliance, accuracy and more.
                <Box pt={3}>
                  <ListWithIcons listItems={secondListItems} />
                </Box>
              </TextBox>
            </Box>
            <Box></Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <Box display="flex" pt={5}>
              <ImageWrapOuter>
                <ImageWrapInner>
                  <AppScreenImageThree
                    src={appScreenThree}
                    alt="App Screen 3"
                  />
                </ImageWrapInner>
              </ImageWrapOuter>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SectionFour;
