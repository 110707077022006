export const COLORS = {
  TURQUOISE: '#50CEA2',
  WHITE: '#FFF',
  LIGHT_GRAY: '#E5E5E5',
  LIGHTER_GRAY2: '#F2F2F2',
  LIGHTER_GRAY: '#F3F3F3',
  GRAY1: '#333333',
  GRAY2: '#4F4F4F',
  GRAY3: '#828282',
  GRAY4: '#002837',
  DARK_BLUE: '#242953'
};