import React from 'react';
import styled from 'styled-components';
import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';

import logoImage from '../assets/images/tangelo-ai-logo.png';
import { Link as RouterLink } from 'react-router-dom';
import LearnMoreLink from './common/LearnMoreLink';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      height: 114,
      background: '#FFF',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
    },
    appBarBox: {
      height: '100%',
    },
    loginButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      flexGrow: 1,
    },
  })
);

const SiteLogo = styled.img`
  display: block;
  height: auto;
  width: 155px;
  @media only screen and (max-width: 600px) {
    width: 50px;
  }
`;

const AppHeader = () => {
  const classes = useStyles();

  const loginOnClick = (event: React.MouseEvent) => {
    event.preventDefault();

    window.open("https://app.tangelo.ai", "_blank")
  };

  return (
    <AppBar position="static" color="secondary" className={classes.appBar}>
      <Box display="flex" alignItems="center" className={classes.appBarBox}>
        <Container disableGutters>
          <Toolbar>
            <div className={classes.logo}>
              <RouterLink to="/">
                <SiteLogo src={logoImage} alt="Logo" />
              </RouterLink>
            </div>
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              className={classes.loginButton}
              onClick={loginOnClick}
            >
              Log in
            </Button>
            <LearnMoreLink />
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  );
};

export default AppHeader;
